$rtl-support: true;

@import '../_appwork/include';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/media';
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/toasts';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/spinners';

// We need to wrap some utility classes to properly handle RTL layout
@import 'node_modules/bootstrap/scss/utilities/align';
@import 'node_modules/bootstrap/scss/utilities/background';
html:not([dir=rtl]) { @import 'node_modules/bootstrap/scss/utilities/borders'; }
@import 'node_modules/bootstrap/scss/utilities/clearfix';
@import 'node_modules/bootstrap/scss/utilities/display';
@import 'node_modules/bootstrap/scss/utilities/embed';
@import 'node_modules/bootstrap/scss/utilities/flex';
@import 'node_modules/bootstrap/scss/utilities/float';
@import "node_modules/bootstrap/scss/utilities/overflow";
@import 'node_modules/bootstrap/scss/utilities/position';
@import 'node_modules/bootstrap/scss/utilities/screenreaders';
@import "node_modules/bootstrap/scss/utilities/shadows";
@import 'node_modules/bootstrap/scss/utilities/sizing';
html:not([dir=rtl]) { @import 'node_modules/bootstrap/scss/utilities/spacing'; }
@import 'node_modules/bootstrap/scss/utilities/text';
@import 'node_modules/bootstrap/scss/utilities/visibility';

@import 'node_modules/bootstrap/scss/print';
